var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增客户"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"iotplt-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"客户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入客户名称' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入客户名称' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"所在城市"}},[_c('a-spin',{attrs:{"spinning":_vm.loadingRegionOption}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['region']),expression:"['region']"}],attrs:{"field-names":{ label: 'name', value: 'id', children: 'cities' },"options":_vm.regionOptions,"show-search":{ regionFilter: _vm.regionFilter },"placeholder":"请选择所在城市"}})],1)],1),_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入登录账号' },
              { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' }
            ]
          }]),expression:"['username', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入登录账号' },\n              { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' }\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"登录密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
            rules: [
              { required: true, message: '请输入登录密码' },
              { min: 6, message: '最少6个字符' },
              { max: 50, message: '最多50个字符' },
              { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
            ]
          }]),expression:"['password', {\n            rules: [\n              { required: true, message: '请输入登录密码' },\n              { min: 6, message: '最少6个字符' },\n              { max: 50, message: '最多50个字符' },\n              { pattern: /^\\S.*\\S$/, message: '首尾不能为空格' }\n            ]\n          }]"}],attrs:{"autocomplete":"new-password"}})],1),_c('a-form-item',{attrs:{"label":"联系对象"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contact', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入联系对象' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['contact', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入联系对象' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入手机号码' },
              { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' } ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入手机号码' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '手机号码格式不正确' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
            normalize: this.$lodash.trim,
            rules: [
              { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' } ]
          }]),expression:"['email', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }